import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {User} from "@/components/globalModels/User";
import {Files} from "@/components/globalModels/Files";


export function TransactionExport() {

  this.checkValid = checkValid

  this.data = {
    User: new User(),
    date: '',
    File: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),
    moduleType: 'export',
    moduleTypes: {
      importType: 'import',
      exportType: 'export',
    },
  }

  this.validation = {
    date: false,
    comment: false,
  }

  this.validationTranslate = {
    date: '',
    comment: '',
  }

  this.validationTxt = {
    date: false,
    comment: false,
  }

}


/*****************   SETTERS   ******************/

// TransactionExport.prototype.setAmount = function(val){
//   this.newPayments.amount = val;
// }
//
// TransactionExport.prototype.setComment = function(val){
//   this.newPayments.comment = val;
// }
//
// TransactionExport.prototype.setHistoryOfChanged = function(val){
//   this.newPayments.historyOfChanged = val;
// }
//
// TransactionExport.prototype.setNewPaymentItem = function(val){
//   this.newPayments.id = val.id;
//   this.newPayments.user = val.user;
//   this.newPayments.userId = val.user.id;
//   this.newPayments.amount = val.payment_total;
//   this.newPayments.comment = val.payment_transaction_detail.description;
//   this.newPayments.status = val.status;
//   this.newPayments.historyOfChanged = val.activityUUID;
// }

/*****************   GETTERS   ******************/
// TransactionExport.prototype.getUser = function(){
//   return this.newPayments.user;
// }
//
// TransactionExport.prototype.getAmount = function(){
//   return this.newPayments.amount;
// }
//
// TransactionExport.prototype.getComment = function(){
//   return this.newPayments.comment;
// }
//
// TransactionExport.prototype.getHistoryOfChanged = function(){
//   return this.newPayments.historyOfChanged;
// }


/*****************   VALIDATION   ******************/

TransactionExport.prototype.exportTransactionValidate = function(){
  let validationItems = {
    date: this.data.date,
  }

  let validationOptions = {
    date: {type: ['empty']},
  }

  return this.checkValid(validationItems, validationOptions) && this.data.User.userValidation({userId: true})
}

TransactionExport.prototype.importTransactionValidate = function(){
  return this.data.File.firstValidation()
}


/****************    PREPARE    ********************/

TransactionExport.prototype.exportTransactionPrepare = function(){
  return {
    userId: this.data.User.getUserId(),
    filter: '?filter[between_created_at]=' + this.data.date[0] + ',' + this.data.date[1]

  }
}




