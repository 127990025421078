<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
          class="clear-992"
          :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <UserInfo/>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.financeNewPayment" class="detail-page__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'payments_NewPayment',
                        ])"></div>
              {{$t('transactionExport_transactionExportPage.localization_value.value')}}
            </div>
<!--            <div class="order-create__head-txt mb-2"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate([-->
<!--                          'payments_NewPaymentDecs',-->
<!--                        ])"></div>-->
<!--              {{$t('transactionExport_.localization_value.value')}}-->
<!--            </div>-->
          </div>
        </template>

        <template slot="body">
          <form class="order-create__section">
            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
                <LargeSwitch
                    :activeValue="TE.data.moduleTypes.exportType"
                    :inactiveValue="TE.data.moduleTypes.importType"
                    :labelActiveValue="$t('transactionExport_exportType.localization_value.value')"
                    :labelInactiveValue="$t('transactionExport_importType.localization_value.value')"
                    :checkedItem="TE.data.moduleType"
                    :name="'orderCreateSwitch'"
                    @change="changeModuleType"
                />
              </div>
            </div>
            <div class="order-create__row custom-row" v-if="TE.data.moduleType === TE.data.moduleTypes.exportType">
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
                <UserSelect
                        :serverError="false"
                        :validationUser="TE.data.User.validation.userId"
                        :validationTxtUser="TE.data.User.validationTxt.userId"
                        :validationTranslateUser="TE.data.User.validationTranslate.userId"
                        :userSelected="TE.data.User.user"
                        @changeUser="changeUser"
                        :typeSelect="'users'"
                        :userEmail="true"
                />
              </div>
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_WasSentAt', ])"></div>
                <DatePickerDefault
                    :label="$t('fbm_WasSentAt.localization_value.value')"
                    :value="TE.data.date"
                    :placeholder="true"
                    v-bind:class="{'ui-no-valid': TE.validation.date}"
                    :errorTxt="$t(`${TE.validationTranslate.date}.localization_value.value`)"
                    :error="TE.validation.date"
                >
                  <template slot="body">
                    <date-picker
                        v-model="TE.data.date"
                        valueType="format"
                        range
                        :placeholder="'YYYY/MM/DD'"
                        :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                        :readonly="true"
                    ></date-picker>
                  </template>
                </DatePickerDefault>
              </div>
            </div>


            <div class="order-create__row custom-row" v-if="TE.data.moduleType === TE.data.moduleTypes.importType">
              <div class="order-create__col custom-col custom-col--md-50 custom-col--sm-100 position-relative">
                <DropZone class="drop-zone-bg"
                          :parentFiles="TE.data.File.data.files"
                          :multiple="true"
                          :maxCount="TE.data.File.data.maxCountFiles - TE.data.File.data.downloadFiles.length"
                          :maxSize="TE.data.File.data.maxSizeFiles"
                          :accept="'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                          @changeImg="changeImg"
                />
                <div class="error-field ml-3" v-if="TE.data.File.validation.files">
                  {{$t('common_fileIsRequired.localization_value.value')}}
                </div>
              </div>
            </div>
<!--            <div class="order-create__row custom-row">-->
<!--              <div class="custom-col custom-col&#45;&#45;66 custom-col&#45;&#45;md-100"-->
<!--                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate([-->
<!--                          'payments_comment',-->
<!--                        ])"></div>-->
<!--                <TextareaDefault-->
<!--                        :label="$t('payments_comment.localization_value.value')"-->
<!--                    :placeholder="'Please be careful with the vase inside!'"-->
<!--                    v-bind:class="{'ui-no-valid': pm.validation.comment}"-->
<!--                    :errorTxt="serverError ? pm.validationTxt.comment : $t(`${pm.validationTranslate.comment}.localization_value.value`)"-->
<!--                    :error="pm.validation.comment"-->
<!--                    v-model="pm.newPayments.comment"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
          </form>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.financeNewPayment"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'common_export',
                          'common_import',
                        ])"></div>

              <MainButton
                  v-if="TE.data.moduleType === TE.data.moduleTypes.exportType"
                      class="order-create__footer-btn-i wfc mr-1"
                      :value="$t('common_export.localization_value.value')"
                      @click.native="$emit('export', true)"
                      v-bind:class="{'disabled-btn' : $store.getters.getCusPaymentsBtn}"
              />
              <MainButton
                  v-if="TE.data.moduleType === TE.data.moduleTypes.importType"
                  class="order-create__footer-btn-i wfc"
                  :value="$t('common_import.localization_value.value')"
                  @click.native="$emit('import')"
                  v-bind:class="{'disabled-btn' : $store.getters.getCusPaymentsBtn}"
              />

            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

    <!--Oleksandr Barabanov-->
    <!--sagapottery@gmail.com-->

  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import UserInfo from "./components/UserInfo/UserInfo";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from "vue2-datepicker";
  import LargeSwitch from "@/components/UI/radiobuttons/LargeSwitch/LargeSwitch";
  import DropZone from "@/components/coreComponents/DropZone/DropZone";

  export default {
    name: "NewPaymentsCreateAdmin",
    components: {
      DropZone,
      LargeSwitch,
      DatePicker,
      DatePickerDefault,
      UserSelect,
      MainButton,
      CardLeftBlock,
      UserInfo,
      CardRightBlock,
      LinkBack,
    },

    props: {
      TE: Object,
      serverError: Boolean,
    },

    data(){
      return{
        options: [{}],
        valueQuantity: 100,
        comment: '',
      }
    },

    methods:{
      changeUser(user) {
        this.TE.data.User.setUser(user)
      },

      changeModuleType(data) {
        this.TE.data.moduleType = data
      },

      changeImg(files, maxSizeError) {
        this.TE.data.File.setFiles(files)

        this.TE.data.File.setFilesMaxSizeError(maxSizeError)
      }
    },

  }
</script>

<style scoped>

</style>
