<template>
  <TransactionExportPageAdmin
          :TE="TE"
          @import="importTransaction"
          @export="exportTransaction"
  />
</template>

<script>
  import TransactionExportPageAdmin
    from "@/components/modules/TransactionExportModule/components/TransactionExportPage/TransactionExportPageAdmin/TransactionExportPageAdmin";
  import {TransactionExport} from "@/components/modules/TransactionExportModule/models/TransactionExport";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

  export default {
    name: "NewPaymentsCreate",

    components: {
      TransactionExportPageAdmin,
    },

    mixins: [routeFilter],

    data() {
      return {
        TE: new TransactionExport(),
        serverError: false,

      }
    },

    mounted() {

    },

    methods: {

      async importTransaction() {
        if(!this.TE.importTransactionValidate()) return

        if (this.TE.data.File.data.files.length > 0) {
          let reader = new FormData();
          reader.append("import-file", this._.first(this.TE.data.File.data.files).file)

          this.$store.dispatch('importTransaction', reader).then((response) => {

            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

                this.openNotify('success', 'common_notificationRecordChanged')
                this.TE.data.File.data.files = []
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })
        }
      },

      async exportTransaction() {
        if(!this.TE.exportTransactionValidate()) return

        let data = this.TE.exportTransactionPrepare()
        console.log(data);

        this.$store.dispatch('getExportTransaction', data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              // this.globalDownloadBase64File('application/docx', response.data.data.file, `${type[0].toUpperCase()}${type.slice(1)}.docx`)
              this.createDownloadLink({data: response.data, name: `Transaction.xls`, type: 'xls'})
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }

        }).catch(error => this.createErrorLog(error))


      },
    },

  }
</script>

<style scoped>

</style>
